import React from 'react'
import Link from 'gatsby-link'

const BookletSummary = ({ booklet, selectedLanguage }) => {
  const validBookletDownloads = booklet.bookletDownloads
    .filter(bd => bd.readFile != null || bd.printFile != null);

  const bookletLanguages = validBookletDownloads
    .reduce((a, b) => {
      return a.concat(b.languages);
    }, [])
    .sort((a, b) => a.ordinal - b.ordinal);

  const languagesFlattened = Array.from(new Set(bookletLanguages.map(l => l.title))).join(" | ");

  let bookletTitle = booklet.title;
  let subTitle = languagesFlattened;
  let bookletDetailSearch = "";

  if (selectedLanguage != null) {
    const relevantBookletDownload = validBookletDownloads
      .filter(bd => bd.languages.some(l => l.id === selectedLanguage.id) && bd.translatedTitle != null)
      .sort((a, b) => a.languages.length - b.languages.length) // Get the one with the least number of languages
      .find(bd => true); // FirstOrDefault

    if (relevantBookletDownload != null) {
      bookletTitle = relevantBookletDownload.translatedTitle;
      subTitle = booklet.title;
      bookletDetailSearch = `?selectedLanguage=${selectedLanguage.title}`;
    }
  }

  return (
    <Link className="post-summary" to={`${booklet.fields.path}${bookletDetailSearch}`}>
      <div className="post-summary-thumbnail" style={{ backgroundColor: booklet.imageBackgroundColour }}>
        <img src={booklet.image.file.url} alt={bookletTitle} />
      </div>
      <div className="post-summary-title">
        <h2>{bookletTitle}</h2>
        <h3>{subTitle}</h3>
      </div>
    </Link>
  );
}

export default BookletSummary
