import React from 'react'
import BookletSummary from '../components/booklet-summary'
import Helmet from 'react-helmet'
import _ from 'lodash'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const Translation = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const language = data.allContentfulLanguage.edges[0].node;
  const pageTitle = `${language.title} Booklets`;

  const allBooklets = _.flatten((language.booklet_download || []).map(bd => bd.booklet))
    .filter(b => b != null);

  const booklets = _.uniqBy(allBooklets, b => b.id)
    .sort((a, b) => a.ordinal - b.ordinal);

  const bookletItems = booklets.map(b => {
    return <li key={b.id}><BookletSummary booklet={b} selectedLanguage={language} /></li>;
  });

  return (
    <Layout>
      <div className="post">
        <Helmet title={`${pageTitle} | ${siteTitle}`} />
        <h2 className="title">{pageTitle}</h2>
        <ul>
          {bookletItems}
        </ul>
      </div>
    </Layout>
  );
}

export default Translation

export const languageSummaryFragment = graphql`
  fragment languageSummary on ContentfulLanguage {
    id
    title
    ordinal
    fields {
      path
    }
  }
`;

export const languageDetailFragment = graphql`
  fragment languageDetail on ContentfulLanguage {
    ...languageSummary
    booklet_download {
      booklet {
        ...bookletSummary
      }
    }
  }
`;

export const languageQuery = graphql`
  query LanguageQuery($id: String!) {
    site {
      ...siteMeta
    }
    allContentfulLanguage(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...languageDetail
        }
      }
    }
  }`;
